import { ReactNode } from 'react'

const strong = (chunks: ReactNode) => <strong>{chunks}</strong>
// react-intl types fail to accept valid configuration, thus any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const br: any = <br />
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const non_breaking_hyphen: any = '‑'
const ul = (text: ReactNode) => <ul>{text}</ul>
const li = (text: ReactNode) => <li>{text}</li>
const p = (text: ReactNode) => <p>{text}</p>

export const richTextConfig = {
  strong,
  br,
  non_breaking_hyphen,
  ul,
  li,
  p,
}
